<template>
  <div class="container-fluid p-4">
    <div class="d-flex justify-content-between">
      <h1>Manage Visitor Type</h1>
      <div class="d-flex justify-content-between">
        <h1 class="mr-3">Total :{{ totalRows }}</h1>
      </div>
    </div>
    <div class="d-flex flex-column pt-4">
      <div class="d-flex flex-column">
        <!-- search visitor -->
        <b-card class="bg-surface-2">
          <b-row class="d-flex justify-content-between w-100 mb-2" no-gutters>
            <b-col>
              <b-form-input
                class="text-truncate"
                id="input-1"
                v-model="$v.searchForm.name.$model"
                placeholder="Search by visitor type "
                v-on:keyup.enter="getSearchVisitorType"
                :state="validateFormSearch('name')"
                aria-describedby="searchForm.name_feedback"
              ></b-form-input>
              <b-form-invalid-feedback
                class="invalid-feedback"
                id="searchForm.name_feedback"
              >
                Your search must be at least 3 character or 3 digit number.
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
          <b-row class="d-flex justify-content-end" no-gutters>
            <b-button
              size="sm"
              variant="dark"
              @click="clearSearch"
              class="d-flex justify-content-end mr-1"
              >Clear
            </b-button>
            <b-button
              size="sm"
              variant="light"
              @click="getSearchVisitorType"
              v-model="searchForm.name"
            >
              Search
            </b-button>
          </b-row>
        </b-card>
        <div class="d-flex justify-content-between pt-4 pb-2">
          <!-- add visitor -->
          <div class="d-flex justify-content-start">
            <b-button
              class="textColor"
              variant="primary"
              size="sm"
              v-b-modal.form-add-visitor-type
              @click="showAddVisitorTypeForm()"
            >
              <i class="fas fa-plus mr-1"></i>Add Visitor Type
            </b-button>
          </div>
        </div>
        <!-- Main table element -->
        <div class="d-flex justify-content-between w-100">
          <b-table
            :busy="busy"
            :items="visitorTypeList"
            :fields="visitorTypeFields"
            :filter-included-fields="filterOn"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="searchTerms"
            class="tableBorder"
            stacked="md"
            responsive
            show-empty
            striped
          >
            <template #table-busy>
              <div class="text-center text-white my-2">
                <b-spinner class="align-middle mr-2"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(index)="row">
              {{ row.index + 1 + (currentPage - 1) * perPage }}
            </template>
            <template #cell(name)="row">
              {{ row.item.name | dashForEmptyText | upperCase }}
            </template>
            <template #cell(visit_duration_minutes)="row">
              {{
                row.item.additional_metadata.visit_duration_minutes
                  | dashForEmptyText
                  | durationConvert
              }}
            </template>

            <template #cell(actions)="row">
              <b-button-group size="sm">
                <!-- edit visitor  -->
                <b-button
                  variant="secondary"
                  size="sm"
                  v-b-modal.form-update-visitor-type
                  v-b-tooltip.hover
                  title="Update Visitor"
                  @click="showUpdateVisitorTypeForm(row.item, row.index)"
                  class="mr-1"
                >
                  <i class="fas fa-edit"></i>
                </b-button>
                <!-- delete visitor  -->
                <b-button
                  variant="danger"
                  size="sm"
                  @click="confirmDelete(row.item, row.index)"
                  v-b-tooltip.hover
                  title="Delete Visitor"
                  class="mr-1"
                >
                  <i class="fas fa-trash-alt"></i>
                </b-button>
              </b-button-group>
            </template>
          </b-table>
        </div>

        <!--pagination-->
        <div
          class="d-flex justify-content-between w-100"
          v-if="!busy && !search"
        >
          <div class="d-flex">
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
              @change="handlePerPageChange"
            ></b-form-select>
            Per Page
          </div>

          <div class="d-flex">
            <b-pagination
              size="sm"
              v-if="perPage !== 'all'"
              class="d-flex"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              @change="handlePageChange"
            ></b-pagination>
          </div>
        </div>
      </div>
      <!-- add visitor type-->
      <b-modal
        id="form-add-visitor-type"
        title="Add Visitor Type"
        ref="formAddVisitorType"
        size="lg"
        centered
        hide-footer
        :no-close-on-backdrop="true"
        @show="resetAddVisitorModal"
        @hidden="resetAddVisitorModal"
      >
        <b-form @submit="onSubmitAddForm">
          <b-form-group id="input-group-visitor-type">
            <label for="input-type"
              >Visitor Type:<span style="color: red">*</span></label
            >
            <b-form-input
              id="input-type"
              v-model="$v.formAdd.name.$model"
              type="text"
              placeholder="Enter Visitor Type"
              :state="validateFormAdd('name')"
              aria-describedby="formAdd.name_feedback"
            ></b-form-input>
            <b-form-invalid-feedback
              class="invalid-feedback"
              id="formAdd.name_feedback"
            >
              This field is required.
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group id="input-group-visitor-type">
            <!--            <b-form-input-->
            <!--                id="input-type"-->
            <!--                v-model="formAdd.visit_duration"-->
            <!--                type="text"-->
            <!--                placeholder="Enter Visit Duration"-->
            <!--            ></b-form-input>-->
            <label for="input-type"
              >Visit Duration:<span style="color: red">*</span></label
            >
            <div>
              <b-row no-gutters>
                <b-col>
                  <div class="d-flex flex-column">
                    <div class="d-flex">
                      <multiselect
                        :style="{
                          border:
                            isValidTimes || isValidSelectedHours
                              ? '1px solid #f86c6b !important'
                              : '1px solid #e8e8e8 !important'
                        }"
                        v-model="$v.visit_hours.$model"
                        :options="duration_hours_list"
                        placeholder="Select hours"
                        label="duration_hours"
                        track-by="duration_hours"
                        @select="onSelect"
                        :state="validateMultiselect('visit_hours')"
                        aria-describedby="visit_hours_feedback"
                      ></multiselect>
                      <span class="mt-2 mx-3">Hours</span>
                    </div>

                    <div
                      v-if="isValidSelectedHours"
                      class="invalid-multiselect-feedback text-danger"
                      id="visit_hours_feedback"
                    >
                      This field is required.
                    </div>
                  </div>
                </b-col>
                <b-col>
                  <div class="d-flex flex-column">
                    <div class="d-flex">
                      <multiselect
                        :style="{
                          border:
                            isValidTimes || isValidSelectedMinutes
                              ? '1px solid #f86c6b !important'
                              : '1px solid #e8e8e8 !important'
                        }"
                        v-model="$v.visit_minutes.$model"
                        :options="duration_minutes_list"
                        placeholder="Select minutes"
                        label="duration_minutes"
                        track-by="duration_minutes"
                        @select="onSelect"
                        :state="validateMultiselect('visit_minutes')"
                        aria-describedby="visit_minutes_feedback"
                      ></multiselect>
                      <span class="mt-2 ml-2">Minutes</span>
                    </div>
                    <div
                      v-if="isValidSelectedMinutes"
                      class="invalid-multiselect-feedback text-danger"
                      id="visit_minutes_feedback"
                    >
                      This field is required.
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-form-group>
          <b-row>
            <b-col>
              <div
                v-if="isValidTimes"
                class="invalid-multiselect-feedback text-danger"
              >
                <h2>This visit duration cannot be zero.</h2>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col class="d-flex flex-row justify-content-end">
              <div class="d-flex">
                <b-button
                  type="submit"
                  variant="primary"
                  :disabled="buttonInactive"
                  >Submit</b-button
                >
              </div>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>

      <!-- update visitor type -->
      <b-modal
        title="Update Visitor Type"
        id="form-update-visitor-type"
        ref="formUpdateVisitorType"
        size="lg"
        centered
        hide-footer
        :no-close-on-backdrop="true"
        @show="resetAddVisitorModal"
        @hidden="resetAddVisitorModal"
      >
        <b-form @submit="onSubmitUpdateForm">
          <b-form-group id="form-update-visitor-type">
            <label for="input-type"
              >Visitor Type:<span style="color: red">*</span></label
            >
            <b-form-input
              id="input-type"
              v-model="$v.formUpdate.name.$model"
              type="text"
              placeholder="Enter Visitor Type"
              :state="validateFormUpdate('name')"
              aria-describedby="formUpdate.name_feedback"
            ></b-form-input>
            <b-form-invalid-feedback
              class="invalid-feedback"
              id="formUpdate.name_feedback"
            >
              This field is required.
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group id="input-group-visitor-type">
            <label for="input-type"
              >Visit Duration:<span style="color: red">*</span></label
            >
            <!--            <b-form-input-->
            <!--                id="input-type"-->
            <!--                v-model="formUpdate.visit_duration"-->
            <!--                type="text"-->
            <!--                placeholder="Enter Visit Duration"-->
            <!--            ></b-form-input>-->
            <div>
              <b-row no-gutters>
                <b-col>
                  <div class="d-flex flex-column">
                    <div class="d-flex">
                      <multiselect
                        :style="{
                          border:
                            isValidTimes || isValidSelectedHours
                              ? '1px solid #f86c6b !important'
                              : '1px solid #e8e8e8 !important'
                        }"
                        v-model="$v.visit_hours.$model"
                        :options="duration_hours_list"
                        placeholder="Select hours"
                        label="duration_hours"
                        track-by="duration_hours"
                        @select="onSelect"
                        :state="validateMultiselect('visit_hours')"
                        aria-describedby="visit_hours_feedback"
                      ></multiselect>
                      <span class="mt-2 mx-2">Hours</span>
                    </div>

                    <div
                      v-if="isValidSelectedHours"
                      class="invalid-multiselect-feedback text-danger"
                      id="visit_hours_feedback"
                    >
                      This field is required.
                    </div>
                  </div>
                </b-col>
                <b-col>
                  <div class="d-flex flex-column">
                    <div class="d-flex">
                      <multiselect
                        :style="{
                          border:
                            isValidTimes || isValidSelectedMinutes
                              ? '1px solid #f86c6b !important'
                              : '1px solid #e8e8e8 !important'
                        }"
                        v-model="$v.visit_minutes.$model"
                        :options="duration_minutes_list"
                        placeholder="Select minutes"
                        label="duration_minutes"
                        track-by="duration_minutes"
                        @select="onSelect"
                        :state="validateMultiselect('visit_minutes')"
                        aria-describedby="visit_minutes_feedback"
                      ></multiselect>
                      <span class="mt-2 ml-2">Minutes</span>
                    </div>
                    <div
                      v-if="isValidSelectedMinutes"
                      class="invalid-multiselect-feedback text-danger"
                      id="visit_minutes_feedback"
                    >
                      This field is required.
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-form-group>
          <b-row>
            <b-col>
              <div
                v-if="isValidTimes"
                class="invalid-multiselect-feedback text-danger"
              >
                <h2>This visit duration cannot be zero.</h2>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col class="d-flex flex-row justify-content-end">
              <div class="d-flex">
                <b-button type="submit" variant="primary">Submit</b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AjaxFetch from "@/assets/global/js/AjaxFetch.js";
import Multiselect from "vue-multiselect";
import { validationMixin } from "vuelidate";
import {
  required,
  email,
  minLength,
  maxLength,
  between,
  helpers
} from "vuelidate/lib/validators";
import jQuery from "jquery";

let $ = (jQuery = require("jquery"));
$ = $.extend(require("webpack-jquery-ui"));
$ = $.extend(require("webpack-jquery-ui/css"));
$ = $.extend(require("formBuilder"));
$ = $.extend(require("formBuilder/dist/form-render.min.js"));

/*
import Compressor from "compressorjs";
import * as imageConversion from "image-conversion";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

let utils = require("@/assets/global/js/utils.js");
const username_regex = helpers.regex('username_regex', /^(?=[a-zA-Z0-9._]{4,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/);
const regex_nric = helpers.regex(regex_nric, /^\d{6}-\d{2}-\d{4}$/);

*/
export default {
  mixins: [validationMixin],
  components: {
    Multiselect
  },
  data: function () {
    return {
      visitorTypeList: [],
      visitorTypeFields: [
        {
          key: "index",
          label: "No.",
          sortDirection: "desc",
          thStyle: { width: "3%" }
        },
        {
          key: "name",
          label: "Visitor Type",
          sortable: true,
          sortDirection: "desc",
          thStyle: { width: "auto" }
        },
        {
          key: "visit_duration_minutes",
          label: "Visit  Duration",
          sortable: true,
          sortDirection: "desc",
          thStyle: { width: "auto" }
        },
        { key: "actions", label: "Actions", thStyle: { width: "auto" } }
      ],

      sortBy: "id",
      sortDirection: "desc",
      sortDesc: false,

      // visit duration
      visit_hours: {
        duration_hours: null
      },
      visit_minutes: {
        duration_minutes: null
      },
      total_duration: null,
      duration_hours_list: [],
      duration_minutes_list: [],
      //validation visit_duration
      isValidSelectedHours: false,
      isValidSelectedMinutes: false,
      isValidTimes: false,
      buttonInactive: true,
      // table search
      busy: false,
      search: false,
      searchTerms: "",
      selected: [],
      searchForm: {
        name: null
      },

      // pagination
      totalRows: 0,
      currentPage: 1,
      perPage: 5,
      // pageOptions: [5, 10, 15, { value: "all", text: "All" }],
      pageOptions: [5, 10, 15],
      filter: null,
      filterOn: ["visit_type"],
      formAdd: {
        name: null,
        additional_metadata: {
          visit_duration_minutes: null
        }
      },
      formUpdateID: null,
      formUpdate: {
        name: null,
        additional_metadata: {
          visit_duration_minutes: null
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      getCurrentUser: "session/getCurrentUser",
      getAPIServerURL: "session/getAPIServerURL"
    })
  },
  watch: {
    $route: function () {
      if (this.$route.query.per_page === "all") {
        this.currentPage = 1;
        this.perPage = this.$route.query.per_page;
      } else {
        this.currentPage = this.$route.query.page
          ? parseInt(this.$route.query.page)
          : 1;
        this.perPage = this.$route.query.per_page
          ? parseInt(this.$route.query.per_page)
          : 10;
      }
      this.getVisitorTypeList();
    },
    "formAdd.name"(newValue, oldValue) {
      if (newValue != null) {
        this.getDisabledButton();
      }
    }
  },
  validations: {
    searchForm: {
      name: { minLength: minLength(3) }
    },
    formAdd: {
      name: { required }
    },
    formUpdate: {
      name: { required }
    },
    visit_hours: {
      duration_hours: { required }
    },
    visit_minutes: {
      duration_minutes: { required }
    }
  },
  created() {
    if (this.$route.query.per_page === "all") {
      this.currentPage = 1;
      this.perPage = this.$route.query.per_page;
    } else {
      this.currentPage = this.$route.query.page
        ? parseInt(this.$route.query.page)
        : 1;
      this.perPage = this.$route.query.per_page
        ? parseInt(this.$route.query.per_page)
        : 10;
    }
  },
  mounted: function () {
    this.getVisitorTypeList();
    this.getVisitDuration();
  },

  methods: {
    getDisabledButton() {
      if (this.formAdd.name != null || this.formUpdate.name != null) {
        if (this.visit_hours.duration_hours != null) {
          if (this.visit_minutes.duration_minutes != null) {
            this.buttonInactive = false;
          }
        }
      }
    },
    // reset add visitor modal
    resetAddVisitorModal() {
      this.isValidSelectedHours = false;
      this.isValidSelectedMinutes = false;
      this.isValidTimes = false;
      this.buttonInactive = true;
      this.duration_hours_list = [];
      this.duration_minutes_list = [];
      this.getVisitDuration();
    },
    /* handle page change */
    handlePageChange(page) {
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          page: parseInt(page)
        }
      });
    },
    handlePerPageChange(pageSize) {
      if (pageSize === "all") {
        this.$router.push({
          ...this.$route,
          query: {
            per_page: pageSize
          }
        });
      } else {
        this.$router.push({
          ...this.$route,
          query: {
            ...this.$route.query,
            per_page: parseInt(pageSize)
          }
        });
      }
    },

    /* select visitor in checkbox */
    onRowSelected: function (items) {
      this.selected = items;
    },
    selectAllRows: function () {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected: function () {
      this.$refs.selectableTable.clearSelected();
    },
    toggleAll: function (checked) {
      if (checked) {
        this.selectAllRows();
      } else {
        this.clearSelected();
      }
    },

    /* get visitor type list */
    getVisitorTypeList: function () {
      let $this = this;
      this.busy = true;

      let API_URL =
        this.getAPIServerURL + "/api/visitorservice/visitor-type/all/";
      const client = this.$root.getAjaxFetchClient();

      let query = {};

      if (this.perPage === "all") {
        query = null;
      } else {
        query = {
          page_index: this.currentPage,
          page_size: this.perPage
        };
      }
      client
        .getRequest(API_URL, query)
        .then((data) => {
          let validUrl = "";
          let result = data.result;
          if (result != undefined) {
            outer_loop: for (let key in result) {
              if (result[key] != undefined) {
                validUrl = key;
                this.busy = false;
                this.search = false;
                break outer_loop;
              }
            }
          }
          if (result[validUrl] != undefined) {
            if (this.perPage == "all") {
              this.visitorTypeList = result[validUrl];
            } else {
              this.visitorTypeList = result[validUrl]["visitor_types"];
            }
          }

          if (result[validUrl]["total"] !== undefined) {
            this.totalRows = parseInt(result[validUrl]["total"]);
          }

          if (result[validUrl]["page"] !== undefined) {
            this.currentPage = parseInt(result[validUrl]["page"]);
          }

          if (result[validUrl]["size"] !== undefined) {
            this.perPage = parseInt(result[validUrl]["size"]);
          }
        })
        .catch((err) => {
          this.busy = false;
          this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
        });
    },
    // fetchVisitorType(id) {
    //   let $this = this;
    //   let API_URL =
    //       this.getAPIServerURL + "/api/visitorservice/visitor-type/?visitor_type_id=" + id ;
    //   const client = this.$root.getAjaxFetchClient();
    //
    //   client
    //       .getRequest(API_URL)
    //       .then((data) => {
    //         let validUrl = "";
    //         let result = data.result;
    //         if (result != undefined) {
    //           outer_loop: for (let key in result) {
    //             if (result[key] != undefined && Array.isArray(result[key])) {
    //               validUrl = key;
    //               break outer_loop;
    //             }
    //           }
    //         }
    //
    //       })
    //       .catch((err) => {
    //         $this.busy = false;
    //         $this.$store.dispatch("session/addGlobalAlertMessage", {
    //           message_text: err.detail,
    //           message_type: "danger"
    //         });
    //         // if (err.status == 401) {
    //         //   $this.$store.dispatch("session/logoutSession");
    //         // }
    //       });
    // },
    onSelect(option) {
      console.log("option", option);
      let $this = this;
      this.isValidSelectedHours = false;
      this.isValidSelectedMinutes = false;
      if (
        $this.visit_hours.duration_hours == 0 &&
        option.duration_minutes == 0
      ) {
        $this.isValidTimes = true;
      } else if (
        $this.visit_minutes.duration_minutes == 0 &&
        option.duration_hours == 0
      ) {
        $this.isValidTimes = true;
      } else {
        $this.isValidTimes = false;
      }
      $this.getDisabledButton();
    },
    /* show add visitor */
    showAddVisitorTypeForm: function (item, index) {
      this.$nextTick(() => {
        this.$v.$reset();
      });
      let $this = this;
      $this.formAdd.name = null;
      this.visit_hours.duration_hours = null;
      this.visit_minutes.duration_minutes = null;
      $this.formAdd.additional_metadata.visit_duration_minutes = null;
    },
    // onSubmitAddVisitorType
    onSubmitAddForm: function (e) {
      e.preventDefault();
      let $this = this;
      let noValidate = $this.onSubmitValidationAddVisitTypeForm();
      if (noValidate) {
        $this.calculateVisitDuration();
        let API_URL =
          $this.getAPIServerURL + "/api/visitorservice/visitor-type/";
        const client = $this.$root.getAjaxFetchClient();
        client
          .postRequest(API_URL, $this.formAdd)
          .then((data) => {
            $this.getVisitorTypeList();
            $this.$refs.formAddVisitorType.hide();
            if (data.detail != undefined) {
              $this.formAddErrorMessage = data.detail;
              let addVisitorType;
              outer_loop: for (let key in data.result) {
                if (data.result[key] != undefined) {
                  addVisitorType = data.result[key];
                  break outer_loop;
                }
              }

              $this.$store.dispatch("session/addGlobalAlertMessage", {
                message_text:
                  "Visitor Type " +
                  addVisitorType.name +
                  " has been added successfully",
                message_type: "success"
              });

              $this.formAdd.name = null;
              $this.formAddErrorMessage = null;
              $this.formUpdateErrorMessage = null;
            }
          })
          .catch((err) => {
            $this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: err.detail,
              message_type: "danger"
            });
            // if (err.status == 401) {
            //   $this.$store.dispatch("session/logoutSession");
            // }
          });
      } else {
        return false;
      }
    },
    /* show update visitor */
    showUpdateVisitorTypeForm: function (item, index) {
      let $this = this;
      $this.formUpdateID = item.id;
      $this.formUpdate.name = item.name.toUpperCase();
      this.visit_hours.duration_hours = Math.floor(
        item.additional_metadata.visit_duration_minutes / 60
      );
      this.visit_minutes.duration_minutes =
        item.additional_metadata.visit_duration_minutes % 60;
      $this.formUpdateErrorMessage = null;
    },

    onSubmitUpdateForm: function (e) {
      let $this = this;
      e.preventDefault();
      let noValidate = $this.onSubmitValidationUpdateVisitTypeForm();
      if (noValidate) {
        $this.calculateVisitDuration();
        let API_URL =
          $this.getAPIServerURL +
          "/api/visitorservice/visitor-type/?visitor_type_id=" +
          $this.formUpdateID;
        const client = $this.$root.getAjaxFetchClient();
        client
          .putRequest(API_URL, $this.formUpdate)
          .then(() => {
            $this.getVisitorTypeList();
            $this.$refs.formUpdateVisitorType.hide();
            $this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: "Updated visitor type successfully",
              message_type: "success"
            });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        return false;
      }
    },

    /* search visitor */
    getSearchVisitorType: function () {
      let $this = this;
      this.busy = true;

      let query = {
        name: $this.searchForm.name
      };

      query = Object.entries(query).reduce(
        (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
        {}
      );

      let API_URL =
        this.getAPIServerURL + "/api/visitorservice/visitor-type/search/?";

      let queryString = Object.keys(query)
        .map((key) => key + "=" + query[key])
        .join("&");

      if (queryString != "") {
        API_URL += queryString;
        try {
          const client = this.$root.getAjaxFetchClient();
          client
            .getRequest(API_URL)
            .then((data) => {
              let result = data.result;
              if (result != undefined) {
                for (let visitor_url in result) {
                  if (result[visitor_url] !== null) {
                    let visitor_result = result[visitor_url];
                    $this.visitorTypeList = [];
                    for (let key2 in visitor_result) {
                      $this.visitorTypeList.push(visitor_result[key2]);
                    }

                    this.search = true;
                    this.busy = false;
                  } else {
                    $this.visitorTypeList = [];
                    this.search = true;
                    this.busy = false;
                  }
                }
              } else {
                $this.visitorTypeList = [];
                this.search = true;
                this.busy = false;
              }

              this.$v.$reset();
            })
            .catch((err) => {
              console.log("err");
              console.log(err);
            });
        } catch (e) {
          console.log("e");
          console.log(e);
        }
      }
    },
    clearSearch: function () {
      this.search = false;
      this.searchForm = {
        name: null
      };
      this.$route.query.name = "";
      this.$router.push({
        name: this.$route.query.parents_url
      });
      this.$v.$reset();
      this.getVisitorTypeList();
    },

    /* delete visitor */
    confirmDelete: function (visitor_type_data, index) {
      // e.preventDefault();
      let $this = this;
      this.$bvModal
        .msgBoxConfirm(
          "Confirm deleting " + visitor_type_data.name.toUpperCase() + "?",
          {
            centered: true
          }
        )
        .then((value) => {
          if (value == true) {
            $this.deleteVisitor(visitor_type_data.id, visitor_type_data);
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    deleteVisitor: function (visitor_type_id, visitor_type_data) {
      let $this = this;
      let API_URL =
        $this.getAPIServerURL +
        "/api/visitorservice/visitor-type/?visitor_type_id=" +
        visitor_type_id;
      const client = $this.$root.getAjaxFetchClient();
      client
        .deleteRequest(API_URL, visitor_type_data)
        .then((data) => {
          $this.getVisitorTypeList();
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: "Deleted visitor type successfully",
            message_type: "success"
          });
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },

    /* visit duration */
    getVisitDuration: function () {
      let durationHours = [...Array(24).keys()];
      let durationMinutes = [...Array(60).keys()];
      if (durationHours) {
        for (let key in durationHours) {
          let duration = {
            duration_hours: durationHours[key]
          };
          this.duration_hours_list.push(duration);
        }
      }
      if (durationMinutes) {
        for (let key in durationMinutes) {
          let duration = {
            duration_minutes: durationMinutes[key]
          };
          this.duration_minutes_list.push(duration);
        }
      }
    },
    calculateVisitDuration: function () {
      let convert_to_minutes = this.visit_hours.duration_hours * 60;
      this.total_duration =
        convert_to_minutes + this.visit_minutes.duration_minutes;
      this.formAdd.additional_metadata.visit_duration_minutes =
        this.total_duration;
      this.formUpdate.additional_metadata.visit_duration_minutes =
        this.total_duration;
    },

    /* validation */
    validateMultiselect(field) {
      let result;
      if (field != null) {
        const { $dirty, $error } = this.$v[field];
        result = $dirty ? !$error : null;
        return result;
      }
    },
    validateFormAdd(field) {
      let result;
      if (field != null) {
        const { $dirty, $error } = this.$v.formAdd[field];
        result = $dirty ? !$error : null;
        return result;
      }
    },
    validateFormUpdate(field) {
      let result;
      if (field != null) {
        const { $dirty, $error } = this.$v.formUpdate[field];
        result = $dirty ? !$error : null;
        return result;
      }
    },
    /* validation */
    validateFormSearch(field) {
      let result;
      if (field != null) {
        const { $dirty, $error } = this.$v.searchForm[field];
        result = $dirty ? !$error : null;
        return result;
      }
    },

    onSubmitValidationAddVisitTypeForm() {
      let $this = this;
      $this.$v.$touch();
      $this.$v.formAdd.$touch();
      this.isValidSelectedHours = $this.$v.visit_hours.$anyError;
      this.isValidSelectedMinutes = $this.$v.visit_minutes.$anyError;

      if (
        $this.$v.formAdd.$anyError ||
        $this.$v.visit_hours.$anyError ||
        $this.$v.visit_minutes.$anyError ||
        $this.isValidTimes
      ) {
        return false;
      } else {
        return true;
      }
    },
    onSubmitValidationUpdateVisitTypeForm() {
      let $this = this;
      $this.$v.$touch();
      $this.$v.formUpdate.$touch();
      this.isValidSelectedHours = $this.$v.visit_hours.$anyError;
      this.isValidSelectedMinutes = $this.$v.visit_minutes.$anyError;

      if (
        $this.$v.formUpdate.$anyError ||
        $this.$v.visit_hours.$anyError ||
        $this.$v.visit_minutes.$anyError ||
        this.isValidTimes
      ) {
        return false;
      } else {
        return true;
      }
    }
  }
};
</script>

<style lang="scss">
@import "../App.scss"; //"./App.scss";
.invalid-multiselect-feedback {
  //display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #d53e4c;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
